import { Paper, styled } from "@mui/material";
const HeaderWrapper = styled(Paper)({
  // backgroundColor: "#f2f1f6",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  height: "60px",
  lineHeight: "40px",
});
export default HeaderWrapper;
