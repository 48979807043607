// ----------------------------------------------------------------------

import { lazy } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";

const AsyncLogin = lazy(() => import("routes/Guest/Login/Login"));
const AsyncForgetPassword = lazy(() =>
  import("routes/Guest/ForgetPassword/ForgetPassword")
);
const AsyncResendVerification = lazy(() =>
  import("routes/Guest/VerifyEmail/ResendVerification")
);
const AsyncVerifyEmail = lazy(() =>
  import("routes/Guest/VerifyEmail/VerifyEmail")
);
const AsyncUserData = lazy(() => import("routes/User/UserData"));

export default function Router() {
  const accessToken = useSelector((state) => state.auth.accessToken);

  const routes = useRoutes([
    // AUTHENTICATION

    {
      path: "/login",
      element: (
        <PublicRoutes isSignedIn={accessToken}>
          <AsyncLogin />
        </PublicRoutes>
      ),
    },
    {
      path: "/forget-password",
      element: (
        <PublicRoutes isSignedIn={accessToken}>
          <AsyncForgetPassword />
        </PublicRoutes>
      ),
    },
    {
      path: "/resend-verification",
      element: (
        <PublicRoutes isSignedIn={accessToken}>
          <AsyncResendVerification />
        </PublicRoutes>
      ),
    },
    {
      path: "/verify-email",
      element: (
        <PublicRoutes isSignedIn={accessToken}>
          <AsyncVerifyEmail />
        </PublicRoutes>
      ),
    },
    {
      path: "/*",
      element: (
        <ProtectedRoutes isSignedIn={accessToken}>
          <AsyncUserData />
        </ProtectedRoutes>
      ),
      // children: [
      //   {
      //     path: "/*",
      //     element: (
      //       <ProtectedRoutes isSignedIn={accessToken}>
      //         <AsyncUserData />
      //       </ProtectedRoutes>
      //     ),
      //   },
      // ],
    },
  ]);

  return routes;
}
