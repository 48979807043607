import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  set: JSON.parse(localStorage.getItem("set")) || {},
  categories: JSON.parse(localStorage.getItem("categories")) || [],
};

export const kytAccessSlice = createSlice({
  name: "kytAccess",
  initialState,
  reducers: {
    setSetDetails: (state, action) => {
      state.set = action.payload;
      localStorage.setItem("set", JSON.stringify(action.payload));
    },
    setKytCategories: (state, action) => {
      state.categories = action.payload;
      localStorage.setItem("categories", JSON.stringify(action.payload));
    },
  },
});

export const { setSetDetails, setKytCategories } = kytAccessSlice.actions;
export default kytAccessSlice.reducer;
