import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDrawer = styled(Drawer)({
  overflow: "auto",
  width: 280,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: 280,
  },
  "&::-webkit-scrollbar": {
    width: "0.2em",
    height: "0.1em",
  },
  "&::-webkit-scrollbar-track": {
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
});
