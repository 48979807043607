import { Box } from "@mui/material";
import { StyledDrawer } from "components/_generics/drawer/StyledDrawer";
import Logo from "components/_generics/logo/Logo";
import SidebarList from "./SidebarList";

export default function Sidebar() {
  return (
    // MUI DRAWER STYLED
    <StyledDrawer
      // PaperProps={{ elevation: 1 }}
      variant="permanent"
      anchor="left"
    >
      {/* LOGO  */}
      <Box my={1}>
        <Logo width="50px" fontSize="16px" spacing="0.4rem" />
      </Box>

      <SidebarList spacingBetween />
    </StyledDrawer>
  );
}
