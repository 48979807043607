import { Box, useMediaQuery, useTheme } from "@mui/material";
import { SyncLoader } from "react-spinners";

const RightSideLoading = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <Box
      width={isNonMobileScreens ? "5%" : "15%"}
      mt={isNonMobileScreens ? "10%" : "50%"}
      sx={{
        minWidth: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
      }}
    >
      <SyncLoader color={theme.palette.primary.main} />
    </Box>
  );
};

export default RightSideLoading;
