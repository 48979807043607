import { ThemeProvider } from "@emotion/react";

import { Box, CssBaseline, createTheme } from "@mui/material";
import Header from "components/Header/Header";
import RightSideLoading from "components/RightSideLoading/RightSideLoading";

import BasicPage from "components/_generics/BasicPage";
import DocHeader from "components/_generics/docHead";
import { Suspense, useMemo } from "react";
import Router from "routes/routes";
import { themeSettings } from "theme/theme";

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);

  return (
    <>
      <DocHeader title={"Home"} description={"ai entry home page"} />

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BasicPage>
          <Box flex={`1 1 100%`}>
            <Header />
            <Suspense fallback={<RightSideLoading />}>
              <Router />
            </Suspense>
          </Box>
        </BasicPage>
      </ThemeProvider>
    </>
  );
}

export default App;
