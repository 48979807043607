import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sites: [],
};

export const sitesSlice = createSlice({
  name: "sites",
  initialState: initialState,
  reducers: {
    setSites: (state, action) => {
      state.sites = action.payload;
    },
  },
});

export const { setSites } = sitesSlice.actions;
export default sitesSlice.reducer;
