export const shift = [
  {
    value: "morning",
    name: "morning",
    EN: "Morning",
    JP: "朝番",
  },
  {
    value: "day",
    name: "day",
    EN: "Day",
    JP: "日勤",
  },
  {
    value: "night",
    name: "night",
    EN: "Night",
    JP: "夜勤",
  },
];

// image classified category
export const category = [
  {
    value: "roof",
    name: "roof",
    EN: "Roof",
    JP: "屋根",
  },
  {
    value: "exterior_wall",
    name: "exterior_wall",
    EN: "Exterior wall",
    JP: "外壁",
  },
  {
    value: "sheet_metal",
    name: "sheet_metal",
    EN: "Sheet metal",
    JP: "板金",
  },
  {
    value: "bargeboard/soffit",
    name: "bargeboard/soffit",
    EN: "Bargeboard/Soffit",
    JP: "破風・軒天ー",
  },
  {
    value: "rain_gutter",
    name: "rain_gutter",
    EN: "Rain Gutter",
    JP: "雨樋",
  },
  {
    value: "foundation/other",
    name: "foundation/other",
    EN: "Foundation and others",
    JP: "基礎＆その他",
  },
  {
    value: "materials",
    name: "materials",
    EN: "Materials",
    JP: "材料",
  },
  {
    value: "door",
    name: "door",
    EN: "Door",
    JP: "ドア",
  },
  {
    value: "wooden_window",
    name: "wooden_window",
    EN: "Wooden window",
    JP: "木製窓",
  },
  {
    value: "wooden_parts",
    name: "wooden_parts",
    EN: "Woodern parts",
    JP: "木製部品",
  },
];

/*
 * This is only dummy data for kyt_access,
 * Please delete once kyt list is created
 */
export const kyt_access = [
  {
    id: 0,
    value: "test1",
    label: "Test 1",
  },
  {
    id: 1,
    value: "test2",
    label: "Test 2",
  },
  {
    id: 2,
    value: "test3",
    label: "Test 3",
  },
];

export const area = [
  {
    value: "北海道",
    name: "hokkaido",
    EN: "Hokkaido",
    JP: "北海道",
    prefcodes: [1],
  },
  {
    value: "東北",
    name: "tohoku",
    EN: "Tohoku",
    JP: "東北",
    prefcodes: [2, 3, 4, 5, 6, 7],
  },
  {
    value: "関東",
    name: "kanto",
    EN: "Kanto",
    JP: "関東",
    prefcodes: [8, 9, 10, 11, 12, 13, 14],
  },
  {
    value: "中部",
    name: "chubu",
    EN: "Chubu",
    JP: "中部",
    prefcodes: [15, 16, 17, 18, 19, 20, 21, 22, 23],
  },
  {
    value: "関西",
    name: "kansai",
    EN: "Kansai",
    JP: "関西",
    prefcodes: [24, 25, 26, 27, 28, 29, 30],
  },
  {
    value: "中国地方",
    name: "chugoku",
    EN: "Chugoku",
    JP: "中国地方",
    prefcodes: [31, 32, 33, 34, 35],
  },
  {
    value: "四国",
    name: "shikoku",
    EN: "Shikoku",
    JP: "四国",
    prefcodes: [36, 37, 38, 39],
  },
  {
    value: "九州と沖縄",
    name: "kyushuAndOkinawa",
    EN: "Kyushu and Okinawa",
    JP: "九州と沖縄",
    prefcodes: [40, 41, 42, 43, 44, 45, 46, 47],
  },
];

export const prefectures = [
  {
    value: 1,
    EN: "北海道", // Hokkaido
    JP: "北海道",
  },
  {
    value: 2,
    EN: "青森県", // Aomori
    JP: "青森県",
  },
  {
    value: 3,
    EN: "岩手県", // Iwate
    JP: "岩手県",
  },
  {
    value: 4,
    EN: "宮城県", // Miyagi
    JP: "宮城県",
  },
  {
    value: 5,
    EN: "秋田県", // Akita
    JP: "秋田県",
  },
  {
    value: 6,
    EN: "山形県", // Yamagata
    JP: "山形県",
  },
  {
    value: 7,
    EN: "福島県", // Fukushima
    JP: "福島県",
  },
  {
    value: 8,
    EN: "茨城県", // Ibaraki
    JP: "茨城県",
  },
  {
    value: 9,
    EN: "栃木県", // Tochigi
    JP: "栃木県",
  },
  {
    value: 10,
    EN: "群馬県", // Gunma
    JP: "群馬県",
  },
  {
    value: 11,
    EN: "埼玉県", // Saitama
    JP: "埼玉県",
  },
  {
    value: 12,
    EN: "千葉県", // Chiba
    JP: "千葉県",
  },
  {
    value: 13,
    EN: "東京都", // Tokyo
    JP: "東京都",
  },
  {
    value: 14,
    EN: "神奈川県", // Kanagawa
    JP: "神奈川県",
  },
  {
    value: 15,
    EN: "新潟県", // Niigata
    JP: "新潟県",
  },
  {
    value: 16,
    EN: "富山県", // Toyama
    JP: "富山県",
  },
  {
    value: 17,
    EN: "石川県", // Ishikawa
    JP: "石川県",
  },
  {
    value: 18,
    EN: "福井県", // Fukui
    JP: "福井県",
  },
  {
    value: 19,
    EN: "山梨県", // Yamanashi
    JP: "山梨県",
  },
  {
    value: 20,
    EN: "長野県", // Nagano
    JP: "長野県",
  },
  {
    value: 21,
    EN: "岐阜県", // Gifu
    JP: "岐阜県",
  },
  {
    value: 22,
    EN: "静岡県", // Shizuoka
    JP: "静岡県",
  },
  {
    value: 23,
    EN: "愛知県", // Aichi
    JP: "愛知県",
  },
  {
    value: 24,
    EN: "三重県", // Mie
    JP: "三重県",
  },
  {
    value: 25,
    EN: "滋賀県", // Shiga
    JP: "滋賀県",
  },
  {
    value: 26,
    EN: "京都府", // Kyoto
    JP: "京都府",
  },
  {
    value: 27,
    EN: "大阪府", // Osaka
    JP: "大阪府",
  },
  {
    value: 28,
    EN: "兵庫県", // Hyogo
    JP: "兵庫県",
  },
  {
    value: 29,
    EN: "奈良県", // Nara
    JP: "奈良県",
  },
  {
    value: 30,
    EN: "和歌山県", // Wakayama
    JP: "和歌山県",
  },
  {
    value: 31,
    EN: "鳥取県", // Tottori
    JP: "鳥取県",
  },
  {
    value: 32,
    EN: "島根県", // Shimane
    JP: "島根県",
  },
  {
    value: 33,
    EN: "岡山県", // Okayama
    JP: "岡山県",
  },
  {
    value: 34,
    EN: "広島県", // Hiroshima
    JP: "広島県",
  },
  {
    value: 35,
    EN: "山口県", // Yamaguchi
    JP: "山口県",
  },
  {
    value: 36,
    EN: "徳島県", // Tokushima
    JP: "徳島県",
  },
  {
    value: 37,
    EN: "香川県", // Kagawa
    JP: "香川県",
  },
  {
    value: 38,
    EN: "愛媛県", // Ehime
    JP: "愛媛県",
  },
  {
    value: 39,
    EN: "高知県", // Kochi
    JP: "高知県",
  },
  {
    value: 40,
    EN: "福岡県", // Fukuoka
    JP: "福岡県",
  },
  {
    value: 41,
    EN: "佐賀県", // Saga
    JP: "佐賀県",
  },
  {
    value: 42,
    EN: "長崎県", // Nagasaki
    JP: "長崎県",
  },
  {
    value: 43,
    EN: "熊本県", // Kumamoto
    JP: "熊本県",
  },
  {
    value: 44,
    EN: "大分県", // Oita
    JP: "大分県",
  },
  {
    value: 45,
    EN: "宮崎県", // Miyazaki
    JP: "宮崎県",
  },
  {
    value: 46,
    EN: "鹿児島県", // Kagoshima
    JP: "鹿児島県",
  },
  {
    value: 47,
    EN: "沖縄県", // Okinawa
    JP: "沖縄県",
  },
];

export const attendanceType = [
  {
    name: "in",
    value: "in",
  },
  {
    name: "out",
    value: "out",
  },
];

export const permissions = [
  "Workplace",
  "Attendance",
  "File",
  // "KYTReport",
  "Employee",
  "Kyt",
  "Permission",
];

export const timezones = [
  {
    value: "Dateline Standard Time",
    abbr: "DST",
    offset: -12,
    isdst: false,
    text: "(UTC-12:00) International Date Line West",
    utc: ["Etc/GMT+12"],
  },
  {
    value: "UTC-11",
    abbr: "U",
    offset: -11,
    isdst: false,
    text: "(UTC-11:00) Coordinated Universal Time-11",
    utc: ["Etc/GMT+11", "Pacific/Midway", "Pacific/Niue", "Pacific/Pago_Pago"],
  },
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    utc: [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti",
    ],
  },
  {
    value: "Alaskan Standard Time",
    abbr: "AKDT",
    offset: -8,
    isdst: true,
    text: "(UTC-09:00) Alaska",
    utc: [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
    ],
  },
  {
    value: "Pacific Standard Time (Mexico)",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-08:00) Baja California",
    utc: ["America/Santa_Isabel"],
  },
  {
    value: "Pacific Daylight Time",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Pacific Daylight Time (US & Canada)",
    utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
  },
  {
    value: "Pacific Standard Time",
    abbr: "PST",
    offset: -8,
    isdst: false,
    text: "(UTC-08:00) Pacific Standard Time (US & Canada)",
    utc: [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT",
    ],
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    utc: [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7",
    ],
  },
  {
    value: "Mountain Standard Time (Mexico)",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    utc: ["America/Chihuahua", "America/Mazatlan"],
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT",
    ],
  },
  {
    value: "Central America Standard Time",
    abbr: "CAST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Central America",
    utc: [
      "America/Belize",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Managua",
      "America/Tegucigalpa",
      "Etc/GMT+6",
      "Pacific/Galapagos",
    ],
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT",
    ],
  },
  {
    value: "Central Standard Time (Mexico)",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    utc: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
    ],
  },
  {
    value: "Canada Central Standard Time",
    abbr: "CCST",
    offset: -6,
    isdst: false,
    text: "(UTC-06:00) Saskatchewan",
    utc: ["America/Regina", "America/Swift_Current"],
  },
  {
    value: "SA Pacific Standard Time",
    abbr: "SPST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Bogota, Lima, Quito",
    utc: [
      "America/Bogota",
      "America/Cayman",
      "America/Coral_Harbour",
      "America/Eirunepe",
      "America/Guayaquil",
      "America/Jamaica",
      "America/Lima",
      "America/Panama",
      "America/Rio_Branco",
      "Etc/GMT+5",
    ],
  },
  {
    value: "Eastern Standard Time",
    abbr: "EST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    value: "Eastern Daylight Time",
    abbr: "EDT",
    offset: -4,
    isdst: true,
    text: "(UTC-04:00) Eastern Daylight Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Indiana (East)",
    utc: [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis",
    ],
  },
  {
    value: "Venezuela Standard Time",
    abbr: "VST",
    offset: -4.5,
    isdst: false,
    text: "(UTC-04:30) Caracas",
    utc: ["America/Caracas"],
  },
  {
    value: "Paraguay Standard Time",
    abbr: "PYT",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Asuncion",
    utc: ["America/Asuncion"],
  },
  {
    value: "Atlantic Standard Time",
    abbr: "ADT",
    offset: -3,
    isdst: true,
    text: "(UTC-04:00) Atlantic Time (Canada)",
    utc: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
      "America/Thule",
      "Atlantic/Bermuda",
    ],
  },
  {
    value: "Central Brazilian Standard Time",
    abbr: "CBST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Cuiaba",
    utc: ["America/Campo_Grande", "America/Cuiaba"],
  },
  {
    value: "SA Western Standard Time",
    abbr: "SWST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    utc: [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Curacao",
      "America/Dominica",
      "America/Grand_Turk",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Tortola",
      "Etc/GMT+4",
    ],
  },
  {
    value: "Pacific SA Standard Time",
    abbr: "PSST",
    offset: -4,
    isdst: false,
    text: "(UTC-04:00) Santiago",
    utc: ["America/Santiago", "Antarctica/Palmer"],
  },
  {
    value: "Newfoundland Standard Time",
    abbr: "NDT",
    offset: -2.5,
    isdst: true,
    text: "(UTC-03:30) Newfoundland",
    utc: ["America/St_Johns"],
  },
  {
    value: "E. South America Standard Time",
    abbr: "ESAST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Brasilia",
    utc: ["America/Sao_Paulo"],
  },
  {
    value: "Argentina Standard Time",
    abbr: "AST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Buenos Aires",
    utc: [
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Buenos_Aires",
      "America/Catamarca",
      "America/Cordoba",
      "America/Jujuy",
      "America/Mendoza",
    ],
  },
  {
    value: "SA Eastern Standard Time",
    abbr: "SEST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Cayenne, Fortaleza",
    utc: [
      "America/Araguaina",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Maceio",
      "America/Paramaribo",
      "America/Recife",
      "America/Santarem",
      "Antarctica/Rothera",
      "Atlantic/Stanley",
      "Etc/GMT+3",
    ],
  },
  {
    value: "Greenland Standard Time",
    abbr: "GDT",
    offset: -3,
    isdst: true,
    text: "(UTC-03:00) Greenland",
    utc: ["America/Godthab"],
  },
  {
    value: "Montevideo Standard Time",
    abbr: "MST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Montevideo",
    utc: ["America/Montevideo"],
  },
  {
    value: "Bahia Standard Time",
    abbr: "BST",
    offset: -3,
    isdst: false,
    text: "(UTC-03:00) Salvador",
    utc: ["America/Bahia"],
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
  },
  {
    value: "Mid-Atlantic Standard Time",
    abbr: "MDT",
    offset: -1,
    isdst: true,
    text: "(UTC-02:00) Mid-Atlantic - Old",
    utc: [],
  },
  {
    value: "Azores Standard Time",
    abbr: "ADT",
    offset: 0,
    isdst: true,
    text: "(UTC-01:00) Azores",
    utc: ["America/Scoresbysund", "Atlantic/Azores"],
  },
  {
    value: "Cape Verde Standard Time",
    abbr: "CVST",
    offset: -1,
    isdst: false,
    text: "(UTC-01:00) Cape Verde Is.",
    utc: ["Atlantic/Cape_Verde", "Etc/GMT+1"],
  },
  {
    value: "Morocco Standard Time",
    abbr: "MDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Casablanca",
    utc: ["Africa/Casablanca", "Africa/El_Aaiun"],
  },
  {
    value: "UTC",
    abbr: "UTC",
    offset: 0,
    isdst: false,
    text: "(UTC) Coordinated Universal Time",
    utc: ["America/Danmarkshavn", "Etc/GMT"],
  },
  {
    value: "GMT Standard Time",
    abbr: "GMT",
    offset: 0,
    isdst: false,
    text: "(UTC) Edinburgh, London",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "British Summer Time",
    abbr: "BST",
    offset: 1,
    isdst: true,
    text: "(UTC+01:00) Edinburgh, London",
    utc: [
      "Europe/Isle_of_Man",
      "Europe/Guernsey",
      "Europe/Jersey",
      "Europe/London",
    ],
  },
  {
    value: "GMT Standard Time",
    abbr: "GDT",
    offset: 1,
    isdst: true,
    text: "(UTC) Dublin, Lisbon",
    utc: [
      "Atlantic/Canary",
      "Atlantic/Faeroe",
      "Atlantic/Madeira",
      "Europe/Dublin",
      "Europe/Lisbon",
    ],
  },
  {
    value: "Greenwich Standard Time",
    abbr: "GST",
    offset: 0,
    isdst: false,
    text: "(UTC) Monrovia, Reykjavik",
    utc: [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena",
    ],
  },
  {
    value: "W. Europe Standard Time",
    abbr: "WEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    utc: [
      "Arctic/Longyearbyen",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Berlin",
      "Europe/Busingen",
      "Europe/Gibraltar",
      "Europe/Luxembourg",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Stockholm",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Zurich",
    ],
  },
  {
    value: "Central Europe Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    utc: [
      "Europe/Belgrade",
      "Europe/Bratislava",
      "Europe/Budapest",
      "Europe/Ljubljana",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Tirane",
    ],
  },
  {
    value: "Romance Standard Time",
    abbr: "RDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    utc: [
      "Africa/Ceuta",
      "Europe/Brussels",
      "Europe/Copenhagen",
      "Europe/Madrid",
      "Europe/Paris",
    ],
  },
  {
    value: "Central European Standard Time",
    abbr: "CEDT",
    offset: 2,
    isdst: true,
    text: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    utc: ["Europe/Sarajevo", "Europe/Skopje", "Europe/Warsaw", "Europe/Zagreb"],
  },
  {
    value: "W. Central Africa Standard Time",
    abbr: "WCAST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) West Central Africa",
    utc: [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Douala",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Etc/GMT-1",
    ],
  },
  {
    value: "Namibia Standard Time",
    abbr: "NST",
    offset: 1,
    isdst: false,
    text: "(UTC+01:00) Windhoek",
    utc: ["Africa/Windhoek"],
  },
  {
    value: "GTB Standard Time",
    abbr: "GDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Athens, Bucharest",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
    ],
  },
  {
    value: "Middle East Standard Time",
    abbr: "MEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Beirut",
    utc: ["Asia/Beirut"],
  },
  {
    value: "Egypt Standard Time",
    abbr: "EST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Cairo",
    utc: ["Africa/Cairo"],
  },
  {
    value: "Syria Standard Time",
    abbr: "SDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Damascus",
    utc: ["Asia/Damascus"],
  },
  {
    value: "E. Europe Standard Time",
    abbr: "EEDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) E. Europe",
    utc: [
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kyiv",
      "Europe/Mariehamn",
      "Europe/Nicosia",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "South Africa Standard Time",
    abbr: "SAST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Harare, Pretoria",
    utc: [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Etc/GMT-2",
    ],
  },
  {
    value: "FLE Standard Time",
    abbr: "FDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    utc: [
      "Europe/Helsinki",
      "Europe/Kyiv",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye",
    ],
  },
  {
    value: "Turkey Standard Time",
    abbr: "TDT",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Istanbul",
    utc: ["Europe/Istanbul"],
  },
  {
    value: "Israel Standard Time",
    abbr: "JDT",
    offset: 3,
    isdst: true,
    text: "(UTC+02:00) Jerusalem",
    utc: ["Asia/Jerusalem"],
  },
  {
    value: "Libya Standard Time",
    abbr: "LST",
    offset: 2,
    isdst: false,
    text: "(UTC+02:00) Tripoli",
    utc: ["Africa/Tripoli"],
  },
  {
    value: "Jordan Standard Time",
    abbr: "JST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Amman",
    utc: ["Asia/Amman"],
  },
  {
    value: "Arabic Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Baghdad",
    utc: ["Asia/Baghdad"],
  },
  {
    value: "Kaliningrad Standard Time",
    abbr: "KST",
    offset: 3,
    isdst: false,
    text: "(UTC+02:00) Kaliningrad",
    utc: ["Europe/Kaliningrad"],
  },
  {
    value: "Arab Standard Time",
    abbr: "AST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Kuwait, Riyadh",
    utc: [
      "Asia/Aden",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh",
    ],
  },
  {
    value: "E. Africa Standard Time",
    abbr: "EAST",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Nairobi",
    utc: [
      "Africa/Addis_Ababa",
      "Africa/Asmera",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Khartoum",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Etc/GMT-3",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte",
    ],
  },
  {
    value: "Moscow Standard Time",
    abbr: "MSK",
    offset: 3,
    isdst: false,
    text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
    utc: [
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Europe/Volgograd",
      "Europe/Minsk",
    ],
  },
  {
    value: "Samara Time",
    abbr: "SAMT",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Samara, Ulyanovsk, Saratov",
    utc: ["Europe/Astrakhan", "Europe/Samara", "Europe/Ulyanovsk"],
  },
  {
    value: "Iran Standard Time",
    abbr: "IDT",
    offset: 4.5,
    isdst: true,
    text: "(UTC+03:30) Tehran",
    utc: ["Asia/Tehran"],
  },
  {
    value: "Arabian Standard Time",
    abbr: "AST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Abu Dhabi, Muscat",
    utc: ["Asia/Dubai", "Asia/Muscat", "Etc/GMT-4"],
  },
  {
    value: "Azerbaijan Standard Time",
    abbr: "ADT",
    offset: 5,
    isdst: true,
    text: "(UTC+04:00) Baku",
    utc: ["Asia/Baku"],
  },
  {
    value: "Mauritius Standard Time",
    abbr: "MST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Port Louis",
    utc: ["Indian/Mahe", "Indian/Mauritius", "Indian/Reunion"],
  },
  {
    value: "Georgian Standard Time",
    abbr: "GET",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Tbilisi",
    utc: ["Asia/Tbilisi"],
  },
  {
    value: "Caucasus Standard Time",
    abbr: "CST",
    offset: 4,
    isdst: false,
    text: "(UTC+04:00) Yerevan",
    utc: ["Asia/Yerevan"],
  },
  {
    value: "Afghanistan Standard Time",
    abbr: "AST",
    offset: 4.5,
    isdst: false,
    text: "(UTC+04:30) Kabul",
    utc: ["Asia/Kabul"],
  },
  {
    value: "West Asia Standard Time",
    abbr: "WAST",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Ashgabat, Tashkent",
    utc: [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Dushanbe",
      "Asia/Oral",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Etc/GMT-5",
      "Indian/Kerguelen",
      "Indian/Maldives",
    ],
  },
  {
    value: "Yekaterinburg Time",
    abbr: "YEKT",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Yekaterinburg",
    utc: ["Asia/Yekaterinburg"],
  },
  {
    value: "Pakistan Standard Time",
    abbr: "PKT",
    offset: 5,
    isdst: false,
    text: "(UTC+05:00) Islamabad, Karachi",
    utc: ["Asia/Karachi"],
  },
  {
    value: "India Standard Time",
    abbr: "IST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    utc: ["Asia/Kolkata", "Asia/Calcutta"],
  },
  {
    value: "Sri Lanka Standard Time",
    abbr: "SLST",
    offset: 5.5,
    isdst: false,
    text: "(UTC+05:30) Sri Jayawardenepura",
    utc: ["Asia/Colombo"],
  },
  {
    value: "Nepal Standard Time",
    abbr: "NST",
    offset: 5.75,
    isdst: false,
    text: "(UTC+05:45) Kathmandu",
    utc: ["Asia/Kathmandu"],
  },
  {
    value: "Central Asia Standard Time",
    abbr: "CAST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Nur-Sultan (Astana)",
    utc: [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Qyzylorda",
      "Asia/Urumqi",
      "Etc/GMT-6",
      "Indian/Chagos",
    ],
  },
  {
    value: "Bangladesh Standard Time",
    abbr: "BST",
    offset: 6,
    isdst: false,
    text: "(UTC+06:00) Dhaka",
    utc: ["Asia/Dhaka", "Asia/Thimphu"],
  },
  {
    value: "Myanmar Standard Time",
    abbr: "MST",
    offset: 6.5,
    isdst: false,
    text: "(UTC+06:30) Yangon (Rangoon)",
    utc: ["Asia/Rangoon", "Indian/Cocos"],
  },
  {
    value: "SE Asia Standard Time",
    abbr: "SAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    utc: [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Saigon",
      "Asia/Vientiane",
      "Etc/GMT-7",
      "Indian/Christmas",
    ],
  },
  {
    value: "N. Central Asia Standard Time",
    abbr: "NCAST",
    offset: 7,
    isdst: false,
    text: "(UTC+07:00) Novosibirsk",
    utc: ["Asia/Novokuznetsk", "Asia/Novosibirsk", "Asia/Omsk"],
  },
  {
    value: "China Standard Time",
    abbr: "CST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    utc: ["Asia/Hong_Kong", "Asia/Macau", "Asia/Shanghai"],
  },
  {
    value: "North Asia Standard Time",
    abbr: "NAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Krasnoyarsk",
    utc: ["Asia/Krasnoyarsk"],
  },
  {
    value: "Singapore Standard Time",
    abbr: "MPST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Kuala Lumpur, Singapore",
    utc: [
      "Asia/Brunei",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Singapore",
      "Etc/GMT-8",
    ],
  },
  {
    value: "W. Australia Standard Time",
    abbr: "WAST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Perth",
    utc: ["Antarctica/Casey", "Australia/Perth"],
  },
  {
    value: "Taipei Standard Time",
    abbr: "TST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Taipei",
    utc: ["Asia/Taipei"],
  },
  {
    value: "Ulaanbaatar Standard Time",
    abbr: "UST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Ulaanbaatar",
    utc: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
  },
  {
    value: "North Asia East Standard Time",
    abbr: "NAEST",
    offset: 8,
    isdst: false,
    text: "(UTC+08:00) Irkutsk",
    utc: ["Asia/Irkutsk"],
  },
  {
    value: "Japan Standard Time",
    abbr: "JST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    utc: [
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Tokyo",
      "Etc/GMT-9",
      "Pacific/Palau",
    ],
  },
  {
    value: "Korea Standard Time",
    abbr: "KST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Seoul",
    utc: ["Asia/Pyongyang", "Asia/Seoul"],
  },
  {
    value: "Cen. Australia Standard Time",
    abbr: "CAST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Adelaide",
    utc: ["Australia/Adelaide", "Australia/Broken_Hill"],
  },
  {
    value: "AUS Central Standard Time",
    abbr: "ACST",
    offset: 9.5,
    isdst: false,
    text: "(UTC+09:30) Darwin",
    utc: ["Australia/Darwin"],
  },
  {
    value: "E. Australia Standard Time",
    abbr: "EAST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Brisbane",
    utc: ["Australia/Brisbane", "Australia/Lindeman"],
  },
  {
    value: "AUS Eastern Standard Time",
    abbr: "AEST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Canberra, Melbourne, Sydney",
    utc: ["Australia/Melbourne", "Australia/Sydney"],
  },
  {
    value: "West Pacific Standard Time",
    abbr: "WPST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Guam, Port Moresby",
    utc: [
      "Antarctica/DumontDUrville",
      "Etc/GMT-10",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan",
      "Pacific/Truk",
    ],
  },
  {
    value: "Tasmania Standard Time",
    abbr: "TST",
    offset: 10,
    isdst: false,
    text: "(UTC+10:00) Hobart",
    utc: ["Australia/Currie", "Australia/Hobart"],
  },
  {
    value: "Yakutsk Standard Time",
    abbr: "YST",
    offset: 9,
    isdst: false,
    text: "(UTC+09:00) Yakutsk",
    utc: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
  },
  {
    value: "Central Pacific Standard Time",
    abbr: "CPST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Solomon Is., New Caledonia",
    utc: [
      "Antarctica/Macquarie",
      "Etc/GMT-11",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Ponape",
    ],
  },
  {
    value: "Vladivostok Standard Time",
    abbr: "VST",
    offset: 11,
    isdst: false,
    text: "(UTC+11:00) Vladivostok",
    utc: ["Asia/Sakhalin", "Asia/Ust-Nera", "Asia/Vladivostok"],
  },
  {
    value: "New Zealand Standard Time",
    abbr: "NZST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Auckland, Wellington",
    utc: ["Antarctica/McMurdo", "Pacific/Auckland"],
  },
  {
    value: "UTC+12",
    abbr: "U",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Coordinated Universal Time+12",
    utc: [
      "Etc/GMT-12",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis",
    ],
  },
  {
    value: "Fiji Standard Time",
    abbr: "FST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Fiji",
    utc: ["Pacific/Fiji"],
  },
  {
    value: "Magadan Standard Time",
    abbr: "MST",
    offset: 12,
    isdst: false,
    text: "(UTC+12:00) Magadan",
    utc: [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Asia/Magadan",
      "Asia/Srednekolymsk",
    ],
  },
  {
    value: "Kamchatka Standard Time",
    abbr: "KDT",
    offset: 13,
    isdst: true,
    text: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    utc: ["Asia/Kamchatka"],
  },
  {
    value: "Tonga Standard Time",
    abbr: "TST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Nuku'alofa",
    utc: [
      "Etc/GMT-13",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Tongatapu",
    ],
  },
  {
    value: "Samoa Standard Time",
    abbr: "SST",
    offset: 13,
    isdst: false,
    text: "(UTC+13:00) Samoa",
    utc: ["Pacific/Apia"],
  },
];

export const timezoneAsia = [
  {
    name: "Etc/GMT-1",
    description: "+01:00",
    offset: 60,
  },
  {
    name: "Etc/GMT-2",
    description: "+02:00",
    offset: 120,
  },
  {
    name: "Etc/GMT-3",
    description: "+03:00",
    offset: 180,
  },
  {
    name: "Etc/GMT-4",
    description: "+04:00",
    offset: 240,
  },
  {
    name: "Etc/GMT-5",
    description: "+05:00",
    offset: 300,
  },
  {
    name: "Etc/GMT-6",
    description: "+06:00",
    offset: 360,
  },
  {
    name: "Etc/GMT-7",
    description: "+07:00",
    offset: 420,
  },
  {
    name: "Etc/GMT-8",
    description: "+08:00",
    offset: 480,
  },
  {
    name: "Etc/GMT-9",
    description: "+09:00",
    offset: 540,
  },
  {
    name: "Etc/GMT-10",
    description: "+10:00",
    offset: 600,
  },
  {
    name: "Etc/GMT-11",
    description: "+11:00",
    offset: 660,
  },
  {
    name: "Etc/GMT-12",
    description: "+12:00",
    offset: 720,
  },
  {
    name: "Etc/GMT-13",
    description: "+13:00",
    offset: 780,
  },
  {
    name: "Etc/GMT-14",
    description: "+14:00",
    offset: 840,
  },
  {
    name: "Etc/GMT+1",
    description: "-01:00",
    offset: -60,
  },
  {
    name: "Etc/GMT+2",
    description: "-02:00",
    offset: -120,
  },
  {
    name: "Etc/GMT+3",
    description: "-03:00",
    offset: -180,
  },
  {
    name: "Etc/GMT+4",
    description: "-04:00",
    offset: -240,
  },
  {
    name: "Etc/GMT+5",
    description: "-05:00",
    offset: -300,
  },
  {
    name: "Etc/GMT+6",
    description: "-06:00",
    offset: -360,
  },
  {
    name: "Etc/GMT+7",
    description: "-07:00",
    offset: -420,
  },
  {
    name: "Etc/GMT+8",
    description: "-08:00",
    offset: -480,
  },
  {
    name: "Etc/GMT+9",
    description: "-09:00",
    offset: -540,
  },
  {
    name: "Etc/GMT+10",
    description: "-10:00",
    offset: -600,
  },
  {
    name: "Etc/GMT+11",
    description: "-11:00",
    offset: -660,
  },
  {
    name: "Etc/GMT+12",
    description: "-12:00",
    offset: -720,
  },
  {
    name: "America/Eirunepe",
    description: "Acre Time",
    offset: -300,
  },
  {
    name: "America/Porto_Acre",
    description: "Acre Time",
    offset: -300,
  },
  {
    name: "America/Rio_Branco",
    description: "Acre Time",
    offset: -300,
  },
  {
    name: "Brazil/Acre",
    description: "Acre Time",
    offset: -300,
  },
  {
    name: "Asia/Kabul",
    description: "Afghanistan Time",
    offset: 270,
  },
  {
    name: "America/Anchorage",
    description: "Alaska Daylight Time",
    offset: -480,
  },
  {
    name: "America/Juneau",
    description: "Alaska Daylight Time",
    offset: -480,
  },
  {
    name: "America/Nome",
    description: "Alaska Daylight Time",
    offset: -480,
  },
  {
    name: "America/Sitka",
    description: "Alaska Daylight Time",
    offset: -480,
  },
  {
    name: "America/Yakutat",
    description: "Alaska Daylight Time",
    offset: -480,
  },
  {
    name: "US/Alaska",
    description: "Alaska Daylight Time",
    offset: -480,
  },
  {
    name: "Asia/Almaty",
    description: "Alma-Ata Time",
    offset: 360,
  },
  {
    name: "America/Boa_Vista",
    description: "Amazon Time",
    offset: -240,
  },
  {
    name: "America/Campo_Grande",
    description: "Amazon Time",
    offset: -240,
  },
  {
    name: "America/Cuiaba",
    description: "Amazon Time",
    offset: -240,
  },
  {
    name: "America/Manaus",
    description: "Amazon Time",
    offset: -240,
  },
  {
    name: "America/Porto_Velho",
    description: "Amazon Time",
    offset: -240,
  },
  {
    name: "Brazil/West",
    description: "Amazon Time",
    offset: -240,
  },
  {
    name: "Asia/Anadyr",
    description: "Anadyr Time",
    offset: 720,
  },
  {
    name: "Asia/Aqtau",
    description: "Aqtau Time",
    offset: 300,
  },
  {
    name: "Asia/Aqtobe",
    description: "Aqtobe Time",
    offset: 300,
  },
  {
    name: "Asia/Aden",
    description: "Arabia Standard Time",
    offset: 180,
  },
  {
    name: "Asia/Baghdad",
    description: "Arabia Standard Time",
    offset: 180,
  },
  {
    name: "Asia/Bahrain",
    description: "Arabia Standard Time",
    offset: 180,
  },
  {
    name: "Asia/Kuwait",
    description: "Arabia Standard Time",
    offset: 180,
  },
  {
    name: "Asia/Qatar",
    description: "Arabia Standard Time",
    offset: 180,
  },
  {
    name: "Asia/Riyadh",
    description: "Arabia Standard Time",
    offset: 180,
  },
  {
    name: "America/Argentina/Buenos_Aires",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Catamarca",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/ComodRivadavia",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Cordoba",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Jujuy",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/La_Rioja",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Mendoza",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Rio_Gallegos",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Salta",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/San_Juan",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/San_Luis",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Tucuman",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Argentina/Ushuaia",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Buenos_Aires",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Catamarca",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Cordoba",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Jujuy",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Mendoza",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "America/Rosario",
    description: "Argentine Time",
    offset: -180,
  },
  {
    name: "Asia/Yerevan",
    description: "Armenia Time",
    offset: 240,
  },
  {
    name: "America/Glace_Bay",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "America/Goose_Bay",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "America/Halifax",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "America/Moncton",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "America/Thule",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "Atlantic/Bermuda",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "Canada/Atlantic",
    description: "Atlantic Daylight Time",
    offset: -180,
  },
  {
    name: "America/Anguilla",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Antigua",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Aruba",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Barbados",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Blanc-Sablon",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Curacao",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Dominica",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Grand_Turk",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Grenada",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Guadeloupe",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Kralendijk",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Lower_Princes",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Marigot",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Martinique",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Montserrat",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Port_of_Spain",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Puerto_Rico",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Santo_Domingo",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/St_Barthelemy",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/St_Kitts",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/St_Lucia",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/St_Thomas",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/St_Vincent",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Tortola",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "America/Virgin",
    description: "Atlantic Standard Time",
    offset: -240,
  },
  {
    name: "Australia/Darwin",
    description: "Australian Central Standard Time (Northern Territory)",
    offset: 570,
  },
  {
    name: "Australia/North",
    description: "Australian Central Standard Time (Northern Territory)",
    offset: 570,
  },
  {
    name: "Australia/Adelaide",
    description: "Australian Central Standard Time (South Australia)",
    offset: 570,
  },
  {
    name: "Australia/South",
    description: "Australian Central Standard Time (South Australia)",
    offset: 570,
  },
  {
    name: "Australia/Broken_Hill",
    description:
      "Australian Central Standard Time (South Australia/New South Wales)",
    offset: 570,
  },
  {
    name: "Australia/Yancowinna",
    description:
      "Australian Central Standard Time (South Australia/New South Wales)",
    offset: 570,
  },
  {
    name: "Australia/Eucla",
    description: "Australian Central Western Standard Time",
    offset: 525,
  },
  {
    name: "Australia/ACT",
    description: "Australian Eastern Standard Time (New South Wales)",
    offset: 600,
  },
  {
    name: "Australia/Canberra",
    description: "Australian Eastern Standard Time (New South Wales)",
    offset: 600,
  },
  {
    name: "Australia/Currie",
    description: "Australian Eastern Standard Time (New South Wales)",
    offset: 600,
  },
  {
    name: "Australia/NSW",
    description: "Australian Eastern Standard Time (New South Wales)",
    offset: 600,
  },
  {
    name: "Australia/Sydney",
    description: "Australian Eastern Standard Time (New South Wales)",
    offset: 600,
  },
  {
    name: "Australia/Brisbane",
    description: "Australian Eastern Standard Time (Queensland)",
    offset: 600,
  },
  {
    name: "Australia/Lindeman",
    description: "Australian Eastern Standard Time (Queensland)",
    offset: 600,
  },
  {
    name: "Australia/Queensland",
    description: "Australian Eastern Standard Time (Queensland)",
    offset: 600,
  },
  {
    name: "Australia/Hobart",
    description: "Australian Eastern Standard Time (Tasmania)",
    offset: 600,
  },
  {
    name: "Australia/Tasmania",
    description: "Australian Eastern Standard Time (Tasmania)",
    offset: 600,
  },
  {
    name: "Australia/Melbourne",
    description: "Australian Eastern Standard Time (Victoria)",
    offset: 600,
  },
  {
    name: "Australia/Victoria",
    description: "Australian Eastern Standard Time (Victoria)",
    offset: 600,
  },
  {
    name: "Antarctica/Casey",
    description: "Australian Western Standard Time",
    offset: 480,
  },
  {
    name: "Australia/Perth",
    description: "Australian Western Standard Time",
    offset: 480,
  },
  {
    name: "Australia/West",
    description: "Australian Western Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Baku",
    description: "Azerbaijan Summer Time",
    offset: 300,
  },
  {
    name: "Atlantic/Azores",
    description: "Azores Summer Time",
    offset: 0,
  },
  {
    name: "Asia/Dacca",
    description: "Bangladesh Time",
    offset: 360,
  },
  {
    name: "Asia/Dhaka",
    description: "Bangladesh Time",
    offset: 360,
  },
  {
    name: "Asia/Thimbu",
    description: "Bhutan Time",
    offset: 360,
  },
  {
    name: "Asia/Thimphu",
    description: "Bhutan Time",
    offset: 360,
  },
  {
    name: "America/La_Paz",
    description: "Bolivia Time",
    offset: -240,
  },
  {
    name: "Pacific/Bougainville",
    description: "Bougainville Standard Time",
    offset: 660,
  },
  {
    name: "America/Araguaina",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Bahia",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Belem",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Fortaleza",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Maceio",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Recife",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Santarem",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "America/Sao_Paulo",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "Brazil/East",
    description: "Brasilia Time",
    offset: -180,
  },
  {
    name: "Europe/Belfast",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "Europe/Guernsey",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "Europe/Isle_of_Man",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "Europe/Jersey",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "Europe/London",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "GB",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "GB-Eire",
    description: "British Summer Time",
    offset: 60,
  },
  {
    name: "Asia/Brunei",
    description: "Brunei Time",
    offset: 480,
  },
  {
    name: "Atlantic/Cape_Verde",
    description: "Cape Verde Time",
    offset: -60,
  },
  {
    name: "Africa/Blantyre",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Bujumbura",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Gaborone",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Harare",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Kigali",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Lubumbashi",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Lusaka",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "Africa/Maputo",
    description: "Central African Time",
    offset: 120,
  },
  {
    name: "America/Bahia_Banderas",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Chicago",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Indiana/Knox",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Indiana/Tell_City",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Knox_IN",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Matamoros",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Menominee",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Merida",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Mexico_City",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Monterrey",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/North_Dakota/Beulah",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/North_Dakota/Center",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/North_Dakota/New_Salem",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Rainy_River",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Rankin_Inlet",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Resolute",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "America/Winnipeg",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "Canada/Central",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "CST6CDT",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "Mexico/General",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "US/Central",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "US/Indiana-Starke",
    description: "Central Daylight Time",
    offset: -300,
  },
  {
    name: "Africa/Ceuta",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Antarctica/Troll",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Arctic/Longyearbyen",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Atlantic/Jan_Mayen",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "CET",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Amsterdam",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Andorra",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Belgrade",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Berlin",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Bratislava",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Brussels",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Budapest",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Busingen",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Copenhagen",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Gibraltar",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Ljubljana",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Luxembourg",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Madrid",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Malta",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Monaco",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Oslo",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Paris",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Podgorica",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Prague",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Rome",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/San_Marino",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Sarajevo",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Skopje",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Stockholm",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Tirane",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Vaduz",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Vatican",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Vienna",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Warsaw",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Zagreb",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Zurich",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Poland",
    description: "Central European Summer Time",
    offset: 120,
  },
  {
    name: "Africa/Algiers",
    description: "Central European Time",
    offset: 60,
  },
  {
    name: "Africa/Tunis",
    description: "Central European Time",
    offset: 60,
  },
  {
    name: "Asia/Makassar",
    description: "Central Indonesia Time",
    offset: 480,
  },
  {
    name: "Asia/Ujung_Pandang",
    description: "Central Indonesia Time",
    offset: 480,
  },
  {
    name: "America/Belize",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/Costa_Rica",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/El_Salvador",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/Guatemala",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/Managua",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/Regina",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/Swift_Current",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "America/Tegucigalpa",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "Canada/East-Saskatchewan",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "Canada/Saskatchewan",
    description: "Central Standard Time",
    offset: -360,
  },
  {
    name: "Pacific/Guam",
    description: "Chamorro Standard Time",
    offset: 600,
  },
  {
    name: "Pacific/Saipan",
    description: "Chamorro Standard Time",
    offset: 600,
  },
  {
    name: "NZ-CHAT",
    description: "Chatham Standard Time",
    offset: 765,
  },
  {
    name: "Pacific/Chatham",
    description: "Chatham Standard Time",
    offset: 765,
  },
  {
    name: "America/Santiago",
    description: "Chile Time",
    offset: -180,
  },
  {
    name: "Antarctica/Palmer",
    description: "Chile Time",
    offset: -180,
  },
  {
    name: "Chile/Continental",
    description: "Chile Time",
    offset: -180,
  },
  {
    name: "Asia/Chongqing",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Chungking",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Harbin",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Macao",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Macau",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Shanghai",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Taipei",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "PRC",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "ROC",
    description: "China Standard Time",
    offset: 480,
  },
  {
    name: "Asia/Choibalsan",
    description: "Choibalsan Summer Time",
    offset: 540,
  },
  {
    name: "Indian/Christmas",
    description: "Christmas Island Time",
    offset: 420,
  },
  {
    name: "Pacific/Chuuk",
    description: "Chuuk Time",
    offset: 600,
  },
  {
    name: "Pacific/Truk",
    description: "Chuuk Time",
    offset: 600,
  },
  {
    name: "Pacific/Yap",
    description: "Chuuk Time",
    offset: 600,
  },
  {
    name: "Indian/Cocos",
    description: "Cocos Islands Time",
    offset: 390,
  },
  {
    name: "America/Bogota",
    description: "Colombia Time",
    offset: -300,
  },
  {
    name: "Pacific/Rarotonga",
    description: "Cook Is. Time",
    offset: -600,
  },
  {
    name: "Etc/UCT",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "Etc/Universal",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "Etc/UTC",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "Etc/Zulu",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "UCT",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "Universal",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "UTC",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "Zulu",
    description: "Coordinated Universal Time",
    offset: 0,
  },
  {
    name: "America/Havana",
    description: "Cuba Daylight Time",
    offset: -240,
  },
  {
    name: "Cuba",
    description: "Cuba Daylight Time",
    offset: -240,
  },
  {
    name: "Antarctica/Davis",
    description: "Davis Time",
    offset: 420,
  },
  {
    name: "Antarctica/DumontDUrville",
    description: "Dumont-d'Urville Time",
    offset: 600,
  },
  {
    name: "Asia/Jayapura",
    description: "East Indonesia Time",
    offset: 540,
  },
  {
    name: "Chile/EasterIsland",
    description: "Easter Is. Time",
    offset: -300,
  },
  {
    name: "Pacific/Easter",
    description: "Easter Is. Time",
    offset: -300,
  },
  {
    name: "Africa/Addis_Ababa",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Asmara",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Asmera",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Dar_es_Salaam",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Djibouti",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Juba",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Kampala",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Khartoum",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Mogadishu",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Africa/Nairobi",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Indian/Antananarivo",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Indian/Comoro",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "Indian/Mayotte",
    description: "Eastern African Time",
    offset: 180,
  },
  {
    name: "America/Cayman",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Detroit",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Fort_Wayne",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indiana/Indianapolis",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indiana/Marengo",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indiana/Petersburg",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indiana/Vevay",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indiana/Vincennes",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indiana/Winamac",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Indianapolis",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Iqaluit",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Kentucky/Louisville",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Kentucky/Monticello",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Louisville",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Montreal",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Nassau",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/New_York",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Nipigon",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Pangnirtung",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Port-au-Prince",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Thunder_Bay",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "America/Toronto",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "Canada/Eastern",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "EST5EDT",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "US/East-Indiana",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "US/Eastern",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "US/Michigan",
    description: "Eastern Daylight Time",
    offset: -240,
  },
  {
    name: "Asia/Amman",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Asia/Beirut",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Asia/Damascus",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Asia/Gaza",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Asia/Hebron",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Asia/Istanbul",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Asia/Nicosia",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "EET",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Athens",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Bucharest",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Chisinau",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Helsinki",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Istanbul",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Kiev",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Mariehamn",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Nicosia",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Riga",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Sofia",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Tallinn",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Tiraspol",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Uzhgorod",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Vilnius",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Europe/Zaporozhye",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Turkey",
    description: "Eastern European Summer Time",
    offset: 180,
  },
  {
    name: "Africa/Cairo",
    description: "Eastern European Time",
    offset: 120,
  },
  {
    name: "Africa/Tripoli",
    description: "Eastern European Time",
    offset: 120,
  },
  {
    name: "Egypt",
    description: "Eastern European Time",
    offset: 120,
  },
  {
    name: "Europe/Kaliningrad",
    description: "Eastern European Time",
    offset: 120,
  },
  {
    name: "Libya",
    description: "Eastern European Time",
    offset: 120,
  },
  {
    name: "America/Scoresbysund",
    description: "Eastern Greenland Summer Time",
    offset: 0,
  },
  {
    name: "America/Atikokan",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "America/Cancun",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "America/Coral_Harbour",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "America/Jamaica",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "America/Panama",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "EST",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "Jamaica",
    description: "Eastern Standard Time",
    offset: -300,
  },
  {
    name: "America/Guayaquil",
    description: "Ecuador Time",
    offset: -300,
  },
  {
    name: "Atlantic/Stanley",
    description: "Falkland Is. Time",
    offset: -180,
  },
  {
    name: "America/Noronha",
    description: "Fernando de Noronha Time",
    offset: -120,
  },
  {
    name: "Brazil/DeNoronha",
    description: "Fernando de Noronha Time",
    offset: -120,
  },
  {
    name: "Pacific/Fiji",
    description: "Fiji Time",
    offset: 720,
  },
  {
    name: "America/Cayenne",
    description: "French Guiana Time",
    offset: -180,
  },
  {
    name: "Indian/Kerguelen",
    description: "French Southern \u0026 Antarctic Lands Time",
    offset: 300,
  },
  {
    name: "Pacific/Galapagos",
    description: "Galapagos Time",
    offset: -360,
  },
  {
    name: "Pacific/Gambier",
    description: "Gambier Time",
    offset: -540,
  },
  {
    name: "Asia/Tbilisi",
    description: "Georgia Time",
    offset: 240,
  },
  {
    name: "Africa/Accra",
    description: "Ghana Mean Time",
    offset: 0,
  },
  {
    name: "Pacific/Tarawa",
    description: "Gilbert Is. Time",
    offset: 720,
  },
  {
    name: "Africa/Abidjan",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Bamako",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Banjul",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Bissau",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Conakry",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Dakar",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Freetown",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Lome",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Monrovia",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Nouakchott",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Ouagadougou",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Sao_Tome",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Africa/Timbuktu",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "America/Danmarkshavn",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Atlantic/Reykjavik",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Atlantic/St_Helena",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Etc/GMT",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Etc/GMT+0",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Etc/GMT-0",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Etc/GMT0",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Etc/Greenwich",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "GMT",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "GMT+0",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "GMT-0",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "GMT0",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Greenwich",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Iceland",
    description: "Greenwich Mean Time",
    offset: 0,
  },
  {
    name: "Asia/Dubai",
    description: "Gulf Standard Time",
    offset: 240,
  },
  {
    name: "Asia/Muscat",
    description: "Gulf Standard Time",
    offset: 240,
  },
  {
    name: "America/Guyana",
    description: "Guyana Time",
    offset: -240,
  },
  {
    name: "America/Adak",
    description: "Hawaii Daylight Time",
    offset: -540,
  },
  {
    name: "America/Atka",
    description: "Hawaii Daylight Time",
    offset: -540,
  },
  {
    name: "US/Aleutian",
    description: "Hawaii Daylight Time",
    offset: -540,
  },
  {
    name: "HST",
    description: "Hawaii Standard Time",
    offset: -600,
  },
  {
    name: "Pacific/Honolulu",
    description: "Hawaii Standard Time",
    offset: -600,
  },
  {
    name: "Pacific/Johnston",
    description: "Hawaii Standard Time",
    offset: -600,
  },
  {
    name: "US/Hawaii",
    description: "Hawaii Standard Time",
    offset: -600,
  },
  {
    name: "Asia/Hong_Kong",
    description: "Hong Kong Time",
    offset: 480,
  },
  {
    name: "Hongkong",
    description: "Hong Kong Time",
    offset: 480,
  },
  {
    name: "Asia/Hovd",
    description: "Hovd Summer Time",
    offset: 480,
  },
  {
    name: "Asia/Calcutta",
    description: "India Standard Time",
    offset: 330,
  },
  {
    name: "Asia/Colombo",
    description: "India Standard Time",
    offset: 330,
  },
  {
    name: "Asia/Kolkata",
    description: "India Standard Time",
    offset: 330,
  },
  {
    name: "Indian/Chagos",
    description: "Indian Ocean Territory Time",
    offset: 360,
  },
  {
    name: "Asia/Bangkok",
    description: "Indochina Time",
    offset: 420,
  },
  {
    name: "Asia/Ho_Chi_Minh",
    description: "Indochina Time",
    offset: 420,
  },
  {
    name: "Asia/Phnom_Penh",
    description: "Indochina Time",
    offset: 420,
  },
  {
    name: "Asia/Saigon",
    description: "Indochina Time",
    offset: 420,
  },
  {
    name: "Asia/Vientiane",
    description: "Indochina Time",
    offset: 420,
  },
  {
    name: "Asia/Tehran",
    description: "Iran Daylight Time",
    offset: 270,
  },
  {
    name: "Iran",
    description: "Iran Daylight Time",
    offset: 270,
  },
  {
    name: "Eire",
    description: "Irish Summer Time",
    offset: 60,
  },
  {
    name: "Europe/Dublin",
    description: "Irish Summer Time",
    offset: 60,
  },
  {
    name: "Asia/Chita",
    description: "Irkutsk Time",
    offset: 480,
  },
  {
    name: "Asia/Irkutsk",
    description: "Irkutsk Time",
    offset: 480,
  },
  {
    name: "Asia/Jerusalem",
    description: "Israel Daylight Time",
    offset: 180,
  },
  {
    name: "Asia/Tel_Aviv",
    description: "Israel Daylight Time",
    offset: 180,
  },
  {
    name: "Israel",
    description: "Israel Daylight Time",
    offset: 180,
  },
  {
    name: "Asia/Tokyo",
    description: "Japan Standard Time",
    offset: 540,
  },
  {
    name: "Japan",
    description: "Japan Standard Time",
    offset: 540,
  },
  {
    name: "Asia/Khandyga",
    description: "Khandyga Time",
    offset: 540,
  },
  {
    name: "Asia/Bishkek",
    description: "Kirgizstan Time",
    offset: 360,
  },
  {
    name: "Asia/Pyongyang",
    description: "Korea Standard Time",
    offset: 540,
  },
  {
    name: "Asia/Seoul",
    description: "Korea Standard Time",
    offset: 540,
  },
  {
    name: "ROK",
    description: "Korea Standard Time",
    offset: 540,
  },
  {
    name: "Pacific/Kosrae",
    description: "Kosrae Time",
    offset: 660,
  },
  {
    name: "Asia/Krasnoyarsk",
    description: "Krasnoyarsk Time",
    offset: 420,
  },
  {
    name: "Asia/Novokuznetsk",
    description: "Krasnoyarsk Time",
    offset: 420,
  },
  {
    name: "Pacific/Kiritimati",
    description: "Line Is. Time",
    offset: 840,
  },
  {
    name: "Australia/LHI",
    description: "Lord Howe Standard Time",
    offset: 630,
  },
  {
    name: "Australia/Lord_Howe",
    description: "Lord Howe Standard Time",
    offset: 630,
  },
  {
    name: "Antarctica/Macquarie",
    description: "Macquarie Island Standard Time",
    offset: 660,
  },
  {
    name: "Asia/Magadan",
    description: "Magadan Time",
    offset: 600,
  },
  {
    name: "Asia/Kuala_Lumpur",
    description: "Malaysia Time",
    offset: 480,
  },
  {
    name: "Asia/Kuching",
    description: "Malaysia Time",
    offset: 480,
  },
  {
    name: "Indian/Maldives",
    description: "Maldives Time",
    offset: 300,
  },
  {
    name: "Pacific/Marquesas",
    description: "Marquesas Time",
    offset: -570,
  },
  {
    name: "Kwajalein",
    description: "Marshall Islands Time",
    offset: 720,
  },
  {
    name: "Pacific/Kwajalein",
    description: "Marshall Islands Time",
    offset: 720,
  },
  {
    name: "Pacific/Majuro",
    description: "Marshall Islands Time",
    offset: 720,
  },
  {
    name: "Indian/Mauritius",
    description: "Mauritius Time",
    offset: 240,
  },
  {
    name: "Antarctica/Mawson",
    description: "Mawson Time",
    offset: 300,
  },
  {
    name: "MET",
    description: "Middle Europe Summer Time",
    offset: 120,
  },
  {
    name: "Europe/Minsk",
    description: "Moscow Standard Time",
    offset: 180,
  },
  {
    name: "Europe/Moscow",
    description: "Moscow Standard Time",
    offset: 180,
  },
  {
    name: "Europe/Simferopol",
    description: "Moscow Standard Time",
    offset: 180,
  },
  {
    name: "Europe/Volgograd",
    description: "Moscow Standard Time",
    offset: 180,
  },
  {
    name: "W-SU",
    description: "Moscow Standard Time",
    offset: 180,
  },
  {
    name: "America/Boise",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Cambridge_Bay",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Chihuahua",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Denver",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Edmonton",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Inuvik",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Mazatlan",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Ojinaga",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Shiprock",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Yellowknife",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "Canada/Mountain",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "Mexico/BajaSur",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "MST7MDT",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "Navajo",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "US/Mountain",
    description: "Mountain Daylight Time",
    offset: -360,
  },
  {
    name: "America/Creston",
    description: "Mountain Standard Time",
    offset: -420,
  },
  {
    name: "America/Dawson_Creek",
    description: "Mountain Standard Time",
    offset: -420,
  },
  {
    name: "America/Hermosillo",
    description: "Mountain Standard Time",
    offset: -420,
  },
  {
    name: "America/Phoenix",
    description: "Mountain Standard Time",
    offset: -420,
  },
  {
    name: "MST",
    description: "Mountain Standard Time",
    offset: -420,
  },
  {
    name: "US/Arizona",
    description: "Mountain Standard Time",
    offset: -420,
  },
  {
    name: "Asia/Rangoon",
    description: "Myanmar Time",
    offset: 390,
  },
  {
    name: "Pacific/Nauru",
    description: "Nauru Time",
    offset: 720,
  },
  {
    name: "Asia/Kathmandu",
    description: "Nepal Time",
    offset: 345,
  },
  {
    name: "Asia/Katmandu",
    description: "Nepal Time",
    offset: 345,
  },
  {
    name: "Pacific/Noumea",
    description: "New Caledonia Time",
    offset: 660,
  },
  {
    name: "Antarctica/McMurdo",
    description: "New Zealand Standard Time",
    offset: 720,
  },
  {
    name: "Antarctica/South_Pole",
    description: "New Zealand Standard Time",
    offset: 720,
  },
  {
    name: "NZ",
    description: "New Zealand Standard Time",
    offset: 720,
  },
  {
    name: "Pacific/Auckland",
    description: "New Zealand Standard Time",
    offset: 720,
  },
  {
    name: "America/St_Johns",
    description: "Newfoundland Daylight Time",
    offset: -150,
  },
  {
    name: "Canada/Newfoundland",
    description: "Newfoundland Daylight Time",
    offset: -150,
  },
  {
    name: "Pacific/Niue",
    description: "Niue Time",
    offset: -660,
  },
  {
    name: "Pacific/Norfolk",
    description: "Norfolk Time",
    offset: 690,
  },
  {
    name: "Asia/Novosibirsk",
    description: "Novosibirsk Time",
    offset: 360,
  },
  {
    name: "Asia/Omsk",
    description: "Omsk Time",
    offset: 360,
  },
  {
    name: "Asia/Oral",
    description: "Oral Time",
    offset: 300,
  },
  {
    name: "America/Dawson",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Ensenada",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Los_Angeles",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Santa_Isabel",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Tijuana",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Vancouver",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Whitehorse",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "Canada/Pacific",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "Canada/Yukon",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "Mexico/BajaNorte",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "PST8PDT",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "US/Pacific",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "US/Pacific-New",
    description: "Pacific Daylight Time",
    offset: -420,
  },
  {
    name: "America/Metlakatla",
    description: "Pacific Standard Time",
    offset: -480,
  },
  {
    name: "Asia/Karachi",
    description: "Pakistan Time",
    offset: 300,
  },
  {
    name: "Pacific/Palau",
    description: "Palau Time",
    offset: 540,
  },
  {
    name: "Pacific/Port_Moresby",
    description: "Papua New Guinea Time",
    offset: 600,
  },
  {
    name: "America/Asuncion",
    description: "Paraguay Time",
    offset: -240,
  },
  {
    name: "America/Lima",
    description: "Peru Time",
    offset: -300,
  },
  {
    name: "Asia/Kamchatka",
    description: "Petropavlovsk-Kamchatski Time",
    offset: 720,
  },
  {
    name: "Asia/Manila",
    description: "Philippines Time",
    offset: 480,
  },
  {
    name: "Pacific/Enderbury",
    description: "Phoenix Is. Time",
    offset: 780,
  },
  {
    name: "America/Miquelon",
    description: "Pierre \u0026 Miquelon Daylight Time",
    offset: -120,
  },
  {
    name: "Pacific/Pitcairn",
    description: "Pitcairn Standard Time",
    offset: -480,
  },
  {
    name: "Pacific/Pohnpei",
    description: "Pohnpei Time",
    offset: 660,
  },
  {
    name: "Pacific/Ponape",
    description: "Pohnpei Time",
    offset: 660,
  },
  {
    name: "Asia/Qyzylorda",
    description: "Qyzylorda Time",
    offset: 360,
  },
  {
    name: "Indian/Reunion",
    description: "Reunion Time",
    offset: 240,
  },
  {
    name: "Antarctica/Rothera",
    description: "Rothera Time",
    offset: -180,
  },
  {
    name: "Asia/Sakhalin",
    description: "Sakhalin Time",
    offset: 600,
  },
  {
    name: "Europe/Samara",
    description: "Samara Time",
    offset: 240,
  },
  {
    name: "Pacific/Midway",
    description: "Samoa Standard Time",
    offset: -660,
  },
  {
    name: "Pacific/Pago_Pago",
    description: "Samoa Standard Time",
    offset: -660,
  },
  {
    name: "Pacific/Samoa",
    description: "Samoa Standard Time",
    offset: -660,
  },
  {
    name: "US/Samoa",
    description: "Samoa Standard Time",
    offset: -660,
  },
  {
    name: "Indian/Mahe",
    description: "Seychelles Time",
    offset: 240,
  },
  {
    name: "Asia/Singapore",
    description: "Singapore Time",
    offset: 480,
  },
  {
    name: "Singapore",
    description: "Singapore Time",
    offset: 480,
  },
  {
    name: "Pacific/Guadalcanal",
    description: "Solomon Is. Time",
    offset: 660,
  },
  {
    name: "Africa/Johannesburg",
    description: "South Africa Standard Time",
    offset: 120,
  },
  {
    name: "Africa/Maseru",
    description: "South Africa Standard Time",
    offset: 120,
  },
  {
    name: "Africa/Mbabane",
    description: "South Africa Standard Time",
    offset: 120,
  },
  {
    name: "Atlantic/South_Georgia",
    description: "South Georgia Standard Time",
    offset: -120,
  },
  {
    name: "Asia/Srednekolymsk",
    description: "Srednekolymsk Time",
    offset: 660,
  },
  {
    name: "America/Paramaribo",
    description: "Suriname Time",
    offset: -180,
  },
  {
    name: "Antarctica/Syowa",
    description: "Syowa Time",
    offset: 180,
  },
  {
    name: "Pacific/Tahiti",
    description: "Tahiti Time",
    offset: -600,
  },
  {
    name: "Asia/Dushanbe",
    description: "Tajikistan Time",
    offset: 300,
  },
  {
    name: "Asia/Dili",
    description: "Timor-Leste Time",
    offset: 540,
  },
  {
    name: "Pacific/Fakaofo",
    description: "Tokelau Time",
    offset: 780,
  },
  {
    name: "Pacific/Tongatapu",
    description: "Tonga Time",
    offset: 780,
  },
  {
    name: "Asia/Ashgabat",
    description: "Turkmenistan Time",
    offset: 300,
  },
  {
    name: "Asia/Ashkhabad",
    description: "Turkmenistan Time",
    offset: 300,
  },
  {
    name: "Pacific/Funafuti",
    description: "Tuvalu Time",
    offset: 720,
  },
  {
    name: "Asia/Ulaanbaatar",
    description: "Ulaanbaatar Summer Time",
    offset: 540,
  },
  {
    name: "Asia/Ulan_Bator",
    description: "Ulaanbaatar Summer Time",
    offset: 540,
  },
  {
    name: "America/Montevideo",
    description: "Uruguay Time",
    offset: -180,
  },
  {
    name: "Asia/Ust-Nera",
    description: "Ust-Nera Time",
    offset: 600,
  },
  {
    name: "Asia/Samarkand",
    description: "Uzbekistan Time",
    offset: 300,
  },
  {
    name: "Asia/Tashkent",
    description: "Uzbekistan Time",
    offset: 300,
  },
  {
    name: "Pacific/Efate",
    description: "Vanuatu Time",
    offset: 660,
  },
  {
    name: "America/Caracas",
    description: "Venezuela Time",
    offset: -270,
  },
  {
    name: "Asia/Vladivostok",
    description: "Vladivostok Time",
    offset: 600,
  },
  {
    name: "Antarctica/Vostok",
    description: "Vostok Time",
    offset: 360,
  },
  {
    name: "Pacific/Wake",
    description: "Wake Time",
    offset: 720,
  },
  {
    name: "Pacific/Wallis",
    description: "Wallis \u0026 Futuna Time",
    offset: 720,
  },
  {
    name: "Asia/Jakarta",
    description: "West Indonesia Time",
    offset: 420,
  },
  {
    name: "Asia/Pontianak",
    description: "West Indonesia Time",
    offset: 420,
  },
  {
    name: "Pacific/Apia",
    description: "West Samoa Standard Time",
    offset: 780,
  },
  {
    name: "Africa/Bangui",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Brazzaville",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Douala",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Kinshasa",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Lagos",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Libreville",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Luanda",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Malabo",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Ndjamena",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Niamey",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Porto-Novo",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Windhoek",
    description: "Western African Time",
    offset: 60,
  },
  {
    name: "Africa/Casablanca",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Africa/El_Aaiun",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Atlantic/Canary",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Atlantic/Faeroe",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Atlantic/Faroe",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Atlantic/Madeira",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Europe/Lisbon",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "Portugal",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "WET",
    description: "Western European Summer Time",
    offset: 60,
  },
  {
    name: "America/Godthab",
    description: "Western Greenland Summer Time",
    offset: -120,
  },
  {
    name: "Asia/Kashgar",
    description: "Xinjiang Standard Time",
    offset: 360,
  },
  {
    name: "Asia/Urumqi",
    description: "Xinjiang Standard Time",
    offset: 360,
  },
  {
    name: "Asia/Yakutsk",
    description: "Yakutsk Time",
    offset: 540,
  },
  {
    name: "Asia/Yekaterinburg",
    description: "Yekaterinburg Time",
    offset: 300,
  },
];

export const timezonesGlobal = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Ciudad_Juarez",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kirov",
  "Europe/Kyiv",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Wake",
  "Pacific/Wallis",
];
