import { Box, useMediaQuery } from "@mui/material";
import Sidebar from "components/Sidebar/Sidebar";
import { useSelector } from "react-redux";

const BasicPage = ({ children }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);

  // const [hasAccess, setHasAccess] = useState(false);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <Box display={`${isNonMobileScreens ? "flex" : "block"}`}>
      {isNonMobileScreens && accessToken && <Sidebar />}
      {children}
    </Box>
  );
};

export default BasicPage;
