import { configureStore } from "@reduxjs/toolkit";
import authReducer from "reducer/auth/authSlice";
import kytAccessReducer from "reducer/kytaccess/kytAccessSlice";
import langReducer from "reducer/lang/langSlice";
import sitesReducer from "reducer/sites/sitesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    lang: langReducer,
    kytAccess: kytAccessReducer,
    sites: sitesReducer,
  },
});
