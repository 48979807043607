export const lang = {
  EN: {
    attendance: "Attendance",
    dashboard: "Dashboard",
    employee: "Employee",
    kytAccess: "KYT Access",
    permission: "User Permission",
    login: "Login",
    signup: "Sign Up",
    logout: "Logout",
    createNew: "CREATE NEW",
    createNewAttendance: "CREATE NEW",
    createNewWorkplace: "CREATE NEW",
    createNewCompany: "CREATE NEW",
    createNewKYTSet: "CREATE NEW",
    back: "BACK",
    workplace: "Workplace",
    profile: "Profile",
    clearImageSelected: "Remove selected image",
    company: "Company",
    gallery: "Image Gallery",
    subscription: "User Subscription",
    file: "File",
    kytReport: "KYT Report",
  },

  JP: {
    attendance: "勤怠管理",
    dashboard: "ダッシュボード",
    employee: "従業員管理",
    kytAccess: "KYT管理",
    permission: "従業員アクセス権限管理",
    login: "ログイン",
    signup: "サインアップ",
    logout: "ログアウト",
    createNew: "従業員の追加",
    createNewAttendance: "勤怠管理の修正",
    createNewWorkplace: "現場管理の追加",
    createNewCompany: "会社の追加",
    createNewKYTSet: "KYTの追加",
    back: "戻る",
    workplace: "現場管理",
    profile: "プロフィール",
    clearImageSelected: "選択した画像を削除",
    company: "会社管理",
    gallery: "画像ギャラリー",
    subscription: "従業員サブスクリプション",
    file: "ファイル管理",
    kytReport: "KYTレポート",
  },
};

export const tableText = {
  EN: {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  },

  JP: {
    rowsPerPageText: "表示数",
    rangeSeparatorText: "の",
  },
};

export const inputLang = {
  EN: {
    email: "EMAIL",
    first_name: "FIRST NAME",
    last_name: "LAST NAME",
    zip_code: "ZIP CODE",
    address_one: "ADDRESS 1",
    address_two: "ADDRESS 2",
    company_id: "COMPANY",
    password: "PASSWORD",
    password_confirmation: "CONFIRM PASSWORD",
    site_id: "SITE",
    kyt_set_id: "KYT ACCESS",
    name: "SITE NAME",
    radius: "RADIUS",
    area: "AREA",
    construction: "CONSTRUCTION DATE",
    construction_start_date: "FROM",
    construction_end_date: "TO",
    real_estate_name: "REAL ESTATE",
    manager_name: "MANAGER NAME",
    sales_person_name: "SALES PERSON NAME",
    is_site_admin: "SITE ADMIN",
    shift: "SHIFT",
    company_name: "COMPANY",
    phone_number: "PHONE NUMBER",
    card_last4: "Card Number",
    card_exp_month: "Month",
    card_exp_year: "Year",
    card_cvv: "CVV",
    plan: "PLAN",
    timezone: "TIMEZONE",
  },
  JP: {
    email: "Eメールアドレス",
    first_name: "名",
    last_name: "姓",
    zip_code: "郵便番号",
    address_one: "住所 1",
    address_two: "住所 2",
    company_id: "会社",
    password: "パスワード",
    password_confirmation: "新しいパスワード（確認用）",
    site_id: "現場",
    kyt_set_id: "アクセス可能なKYT",
    name: "現場名",
    radius: "半径",
    area: "エリア",
    construction: "工事期間",
    construction_start_date: "開始日",
    construction_end_date: "終了日",
    real_estate_name: "クライアント",
    manager_name: "現場責任者",
    sales_person_name: "営業担当者",
    is_site_admin: "CMS管理者",
    shift: "シフト",
    company_name: "会社",
    phone_number: "電話番号",
    card_last4: "カード番号",
    card_exp_month: "月",
    card_exp_year: "年",
    card_cvv: "CVV",
    plan: "プラン",
    timezone: "タイムゾーン",
  },
};

export const submitText = {
  EN: {
    SUBMIT: "SUBMIT",
    PERMISSIONUPDATESUBMIT: "SUBMIT",
    SUBMITEDIT: "SUBMIT",
    SEARCH: "SEARCH",
    SEND: "SEND",
    LOGIN: "LOGIN",
    BACK: "BACK",
    SETADMIN: "SET AS ADMIN",
    UNSETADMIN: "UNSET AS ADMIN",
    delete: "DELETE",
    RESENDVERIFICATION: "RESEND VERIFICATION",
    SETPERMISSIONS: "SET PERMISSIONS",
    CANCEL: "CANCEL",
    CLOSE: "CLOSE",
    SIGNUP: "SIGN UP",
    TRANSFER: "TRANSFER",
  },

  JP: {
    SUBMIT: "追加",
    PERMISSIONUPDATESUBMIT: "更新",
    SUBMITEDIT: "変更",
    SEARCH: "検索",
    SEND: "送信",
    LOGIN: "ログイン",
    BACK: "戻る",
    SETADMIN: "CMS管理者に設定​",
    UNSETADMIN: "CMS管理者を解除",
    delete: "削除",
    RESENDVERIFICATION: "確認メール再送信",
    SETPERMISSIONS: "設定",
    CANCEL: "キャンセル",
    CLOSE: "閉じる",
    SIGNUP: "サインアップ",
    TRANSFER: "移動",
  },
};

export const verification = {
  EN: {
    email: "Email",
    enterEmail: "Please enter email.",
    spacesAreNotValid: "Spaces are not valid as input.",
    invalidEmailFormat: "Invalid email format.",
    thisIsRequired: "This is required.",
    verificationDesc:
      "Please enter your email to send a new verification link.",
    verificationTitle: "Resend Verification",
    backToLogin: "Back to Login",
  },

  JP: {
    email: "Eメールアドレス",
    enterEmail: "Eメールアドレスを入力してください。",
    spacesAreNotValid: "スペースは入力できません。",
    invalidEmailFormat: "不正なeメールアドレス",
    thisIsRequired: "必須項目です。",
    verificationDesc:
      "メールアドレスを入力して、新しい確認URLを送信してください。",
    verificationTitle: "確認メール再送信",
    backToLogin: "ログインページに戻る",
  },
};

export const companySignUp = {
  EN: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    password: "Password",
    password_confirmation: "Password Confirmation",
    name: "Company Name",
    phone_number: "Phone Number",
    zip_code: "Zip Code",
    address_one: "Address One",
    address_two: "Address Two",
    backToLogin: "Back to Login",
    signup: "Sign Up",
    subscribe: "SUBSCRIBE NOW",
    card_number: "Card Number",
    exp_month: "Expiry Month",
    exp_year: "Expiry Year",
    cvc: "CVC",
    card_holders_name: "Card Holder's Name",
    aiEntryMainTitle: "AI ENTRY TIME KEEPING SYSTEM",
    aiEntrySubTitle: "WITH FLEXIBLE PLANS",
  },

  JP: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    password: "Password",
    password_confirmation: "Password Confirmation",
    name: "会社名",
    phone_number: "Phone Number",
    zip_code: "Zip Code",
    address_one: "Address One",
    address_two: "Address Two",
    backToLogin: "Back to Login",
    signup: "Sign Up",
    subscribe: "SUBSCRIBE NOW",
    card_number: "Card Number",
    exp_month: "Expiry Month",
    exp_year: "Expiry Year",
    cvc: "CVC",
    card_holders_name: "Card Holder's Name",
    aiEntryMainTitle: "AI ENTRY TIME KEEPING SYSTEM",
    aiEntrySubTitle: "WITH FLEXIBLE PLANS",
  },
};
