import axios from "endpoints/axios";
import { CLIENT_ID, CLIENT_SECRET } from "../../constants";

export async function loginUser(credentials) {
  const userData = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: credentials.grant_type,
    username: credentials.email,
    password: credentials.password,
  };

  const response = await axios
    .post("oauth/token", userData)
    .then((response) => {
      const successData = {
        ...response.data,
        status: response.status,
      };
      return successData;
    })
    .catch((error) => Promise.reject(error));

  return response;
}
export async function logoutUser() {
  const response = await axios
    .post("api/logout")
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("set");
      localStorage.removeItem("category");
      localStorage.removeItem("system_admin");
      localStorage.removeItem("company_owner");
      localStorage.removeItem("read");
      localStorage.removeItem("execute");
      localStorage.removeItem("profile_picture");
      localStorage.removeItem("profile_pic");
      localStorage.removeItem("id");
      localStorage.removeItem("orientation");
      localStorage.removeItem("hasAccess");
      localStorage.removeItem("companySignupStatus");
      localStorage.removeItem("categories");
      localStorage.removeItem("set");
    });

  return response;
}
