import { ListItemButton, styled } from "@mui/material";

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 1,
  paddingBottom: 10,
  paddingTop: 10,
  "& .MuiListItemIcon-root": {
    minWidth: 45,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  "&.Mui-selected:hover": {
    color: theme.palette.primary.dark,
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.dark,
    },
    "& .MuiTypography-root": {
      color: theme.palette.primary.dark,
    },
  },
  "&:hover": {
    color: "white",
    backgroundColor: theme.palette.primary.light,
    "& .MuiListItemIcon-root": {
      color: "white",
    },
    "& .MuiTypography-root": {
      color: "white",
    },
  },
}));
