import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import Logo from "./logo/Logo";

import SidebarList from "components/Sidebar/SidebarList";
import { useDispatch, useSelector } from "react-redux";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { lang } from "utils/translations/globalLang";
import { StyledListItemButton } from "./StyledListItemButton";

export default function MobileDrawer({ handleLogout }) {
  const langType = useSelector((state) => state.lang.lang);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { pathname } = useLocation();
  const pathName = pathname.split("/")[1];
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const { main, light, dark } = theme.palette.primary;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        p: 1,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingTop={1}
        paddingRight={2}
      >
        <Logo />
        <IconButton>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>

      {/* <List>
        {[
          "Dashboard",
          "Workspace",
          "Attendence",
          "Files",
          "Employee",
          "KYT Access",
          "Usr Permission",
          "Company",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <StyledListItemButton
              component={RouterLink}
              to={
                (index === 0 && "/") ||
                (index === 1 && "/workplace") ||
                (index === 2 && "/attendance") ||
                (index === 3 && "/media") ||
                (index === 4 && "/employee") ||
                (index === 5 && "/kyt") ||
                (index === 6 && "/permission") ||
                (index === 7 && "/company")
              }
              sx={{ my: 0.5, borderRadius: 1 }}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemIcon>
                {index === 0 && <PollIcon />}
                {index === 1 && <LocationOnIcon />}
                {index === 2 && <RecentActorsIcon />}
                {index === 3 && <TextSnippetRoundedIcon />}
                {index === 4 && <PeopleAltIcon />}
                {index === 5 && <SettingsAccessibilityIcon />}
                {index === 6 && <ManageAccountsIcon />}
                {index === 7 && <ApartmentIcon />}
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="h5">{text}</Typography>}
              />
            </StyledListItemButton>
          </ListItem>
        ))}
      </List> */}
      <SidebarList />

      <Divider />
      <List>
        {[lang[langType].profile, lang[langType].logout].map((text, index) => (
          <ListItem key={text + "-" + index} disablePadding>
            <StyledListItemButton
              component={RouterLink}
              to={index === 0 && "/profile"}
              selected={index === 0 && pathName.includes("profile")}
              sx={{ my: 0.5, borderRadius: 1 }}
              onClick={index === 1 && handleLogout}
              key={text + "-" + index}
            >
              <ListItemIcon key={text + "-" + index}>
                {index % 2 === 0 ? <AccountCircleIcon /> : <LogoutIcon />}
              </ListItemIcon>
              <ListItemText
                key={text + "-" + index}
                primary={
                  <Typography
                    fontWeight="bold"
                    variant="h5"
                    color={"text.secondary"}
                  >
                    {text}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <IconButton sx={{ mt: 1 }} onClick={toggleDrawer("left", true)}>
        <HiMenuAlt1 fontSize="28px" color={theme.palette.primary.dark} />
      </IconButton>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 1px)",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          },
        }}
      >
        {list("left")}
      </Drawer>
    </>
  );
}
