import { Box, Stack, Typography, useTheme } from "@mui/material";
import Profile from "assets/images/auth/ae.png";
import { useNavigate } from "react-router-dom";
import React from "react";

const Logo = ({
  width = "48px",
  fontSize = "14px",
  spacing = "0.3rem",
  text1 = "ai",
  text2 = "entry",
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Stack
      direction={"row"}
      spacing={spacing}
      display={"flex"}
      justifyContent={"center"}
      // alignItems={"center"}
      mt={1}
    >
      <Box width={"45px"} height={"45px"} sx={{ cursor: "pointer" }}>
        <img
          style={{ width: width }}
          src={Profile}
          alt="ai entry"
          onClick={() => navigate("/")}
        />
      </Box>
      <Stack
        spacing={"-8px"}
        sx={{
          justifyContent: "center",
          cursor: "pointer",
          my: "auto",
        }}
        onClick={() => navigate("/")}
      >
        <Typography
          fontWeight="bold"
          fontSize={fontSize}
          color={theme.palette.primary.dark}
        >
          {text1}
        </Typography>
        <Typography fontWeight="bold" fontSize={fontSize} color="black">
          {text2}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default React.memo(Logo);
