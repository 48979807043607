import axios from "axios";
import { BASE_API_URL } from "../constants";

const defaultOptions = {
  baseURL: BASE_API_URL,
  headers: {
    Accept: "application/json",
  },
};

const api = axios.create(defaultOptions);

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");

  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

api.interceptors.response.use(
  (response) => {
    // sessionStorage.remoevItem("sessionExpired");
    return response;
  },
  (error) => Promise.reject(error)
);

export default api;
